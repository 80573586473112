<script lang="ts" setup>
import type { Testimonial } from "~/lib/models";

interface Props {
    testimonials: Testimonial[];
}

defineProps<Props>();
const selectedIndex = ref<number>(0);

function nextTestimonial(length: number): number {
    if (selectedIndex.value === length - 1) {
        return 0;
    }

    return selectedIndex.value + 1;
}

onMounted(async () => {
    selectedIndex.value = 0;
});
</script>

<template>
    <VCarousel v-model="selectedIndex" :slide-count="testimonials.length">
        <section>
            <div class="section-content w-full h-full py-16 lg:py-0 lg:h-[31.2rem]">
                <div
                    v-for="(testimonial, idx) in testimonials"
                    :key="idx"
                    class="h-full flex-1 flex-col-reverse items-center gap-6 text-grey-500 text-center lg:flex-row lg:justify-center"
                    :class="{ flex: idx === selectedIndex, hidden: idx !== selectedIndex }"
                >
                    <div
                        class="flex flex-col gap-6 lg:text-left lg:w-[40rem]"
                        :class="{ 'fade-in-out': testimonials.length > 1 }"
                    >
                        <p class="font-medium text-grey-600 text-sm lg:text-base">
                            {{ testimonial.text }}
                        </p>
                        <div class="text-xs lg:text-sm">
                            <p>{{ testimonial.witness }}</p>
                            <span> {{ testimonial.address }}</span>
                        </div>
                    </div>

                    <div class="lg:w-full lg:min-w-[31.2rem] lg:max-w-[32.5rem] lg:h-full">
                        <img :src="testimonial.backgroundSrc" alt="" class="w-full h-full" />
                    </div>

                    <div
                        v-if="testimonials.length > 1"
                        class="hidden lg:flex lg:flex-col lg:gap-6 lg:text-left fade-in-out lg:w-[40rem]"
                    >
                        <p class="font-medium text-grey-600 text-sm lg:text-base">
                            {{ testimonials[nextTestimonial(testimonials.length)].text }}
                        </p>

                        <div class="text-xs lg:text-sm">
                            <p>
                                {{ testimonials[nextTestimonial(testimonials.length)].witness }}
                            </p>
                            <span>
                                {{ testimonials[nextTestimonial(testimonials.length)].address }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </VCarousel>
</template>

<style lang="scss" scoped>
@keyframes fadeinout {
    0%,
    100% {
        opacity: 0.1;
    }
    20%,
    80% {
        opacity: 1;
    }
}

.fade-in-out {
    animation: fadeinout 6s linear forwards;
}
</style>
