import { AntboxError, Either, Node, NodeFilter, NodeServiceClient, left, right } from "../deps";
import type { Testimonial } from "../models";

export class TestimonialService {
    #TESTIMONIAL_BACKGROUND_UUID = "testimonial-background";
    #TESTIMONIAL_UUID = "testimonial";

    constructor(private readonly nodeServiceClient: NodeServiceClient, private readonly tenant: string) {}

    async list(): Promise<Either<AntboxError, Testimonial[]>> {
        const testimonials: Testimonial[] = [];
        const getBackgroundTestimonialQuery: NodeFilter[] = [
            ["aspects", "contains", this.#TESTIMONIAL_BACKGROUND_UUID],
            [`properties.${this.#TESTIMONIAL_BACKGROUND_UUID}:priority`, "==", "Alta"],
        ];

        const priorityBackgroundOrErr = await this.nodeServiceClient.query(getBackgroundTestimonialQuery, 1);

        if (priorityBackgroundOrErr.isLeft()) {
            return left(priorityBackgroundOrErr.value);
        }

        const getTestimonialsByBackgroundQuery: NodeFilter[] = [
            ["aspects", "contains", this.#TESTIMONIAL_UUID],
            [`properties.${this.#TESTIMONIAL_UUID}:${this.#TESTIMONIAL_BACKGROUND_UUID}`, "==", priorityBackgroundOrErr.value.nodes?.[0]?.uuid],
        ];

        const testimonialOrErr = await this.nodeServiceClient.query(getTestimonialsByBackgroundQuery);

        if (testimonialOrErr.isLeft()) return left(testimonialOrErr.value);

        this.#orderTestimonialsByPriority(testimonialOrErr.value.nodes).forEach((t) => {
            testimonials.push(this.#convertNodeToTestimonialModel(t, priorityBackgroundOrErr.value.nodes[0]?.uuid!));
        });

        return right(testimonials);
    }

    #convertNodeToTestimonialModel(node: Node, backgroundUuid: string): Testimonial {
        return {
            id: node.uuid,
            backgroundSrc: this.#getImageUrl(backgroundUuid),
            text: node?.properties?.[`${this.#TESTIMONIAL_UUID}:testimonial`] as string,
            address: node?.properties?.[`${this.#TESTIMONIAL_UUID}:location`] as string,
            witness: node?.properties?.[`${this.#TESTIMONIAL_UUID}:witness`] as string,
        };
    }

    #orderTestimonialsByPriority(nodes: Node[]): Node[] {
        const firstNodes = nodes.filter((node) => {
            return node.properties?.[`${this.#TESTIMONIAL_UUID}:priority`] === "Alta";
        });
        const secondNodes = nodes.filter((node) => {
            return node.properties?.[`${this.#TESTIMONIAL_UUID}:priority`] === "Baixa";
        });
        return [...firstNodes, ...secondNodes];
    }

    #getImageUrl (uuid: string) {
        const url = this.nodeServiceClient.getNodeUrl(uuid)
        return `${url}?x-tenant=${this.tenant}`
    }
}
